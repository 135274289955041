<template>
    <div class="scrolllayout"  @click="handleswiperchoose">
        <diybar :diybarData="diybarData" @handlediybar="handlediybar"></diybar>
        <div :class="swiper1[0].swiperclass==0?'noswiper':'swiper'" :style="'z-index:'+swiper1[0].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[0].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow">
                <div class="swiper-home">
                    <div class="swiper-unit-home">
                        <div class="swiper-unit-right-home">
                            <div>
                                <span class="wy">
                                    科技赋能
                                </span>
                            </div>
                            <div>
                                <span class="wy">
                                    用全力以赴丈量服务的尺度
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-home">
                    <div class="bottom-right-home">
                        <div class="bottom-right-left-home"></div>
                        <div class="swiper1-home" >
                            <div class="swiper1-unit-home">
                              鑫嵘科技一直坚持致力于服务团队的打造，同时注重综合形人才的培养，力求为客户提供安全可靠、敢于担当的专业型服务。每一个项目，我们都将全力以赴。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div :class="swiper1[1].swiperclass==0?'noswiper':(swiper1[1].swiperclass==1?'reswiper':'swiper')" :style="'z-index:'+swiper1[1].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[1].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow">
                <div class="shadow-top">
                    <div class="shadow-top-top">
                        <div class="shadow-top-top-item"><span class="wy" style="font-size: 25px;">只为不同</span></div>
                        <div class="shadow-top-top-item"><span class="wy" style="font-size: 25px;">基于品牌战略下的优质服务</span></div>
                    </div>
                    <div class="shadow-top-bottom">
                      鑫嵘科技依托多年来的客户服务经验，与众多战略合作机构，整合了数字化链路的综合服务，确保了服务与客户战略的匹配度、客户体验的多元性。
                    </div>
                </div>
                <div class="shadow-center">
                    <div class="shadow-center-top">
<!--                        <div class="shadow-center-top-item">-->
<!--                            <div class="shadow-center-top-item-top">-->
<!--                                <img class="img" src="@/img/ser1-1.jpg" alt="">-->
<!--                            </div>-->
<!--                            <div class="shadow-center-top-item-bottom">-->
<!--                                <div class="shadow-center-top-item-bottom-left">-->
<!--                                    <div class="shadow-center-top-item-bottom-left-top">定制</div>-->
<!--                                    <div class="shadow-center-top-item-bottom-left-bottom">品牌形象</div>-->
<!--                                </div>-->
<!--                                <div class="shadow-center-top-item-bottom-right">-->
<!--                                    <img class="img" src="@/img/ser1sign.png" alt="">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="shadow-center-top-item">
                            <div class="shadow-center-top-item-top">
                                <img class="img" src="@/img/ser1-2.jpg" alt="">
                            </div>
                            <div class="shadow-center-top-item-bottom">
                                <div class="shadow-center-top-item-bottom-left">
                                    <div class="shadow-center-top-item-bottom-left-top">搭建</div>
                                    <div class="shadow-center-top-item-bottom-left-bottom">营销系统</div>
                                </div>
                                <div class="shadow-center-top-item-bottom-right">
                                    <img class="img" src="@/img/ser1sign.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="shadow-center-top-item">
                            <div class="shadow-center-top-item-top">
                                <img class="img" src="@/img/ser1-3.jpg" alt="">
                            </div>
                            <div class="shadow-center-top-item-bottom">
                                <div class="shadow-center-top-item-bottom-left">
                                    <div class="shadow-center-top-item-bottom-left-top">开发</div>
                                    <div class="shadow-center-top-item-bottom-left-bottom">管理系统</div>
                                </div>
                                <div class="shadow-center-top-item-bottom-right">
                                    <img class="img" src="@/img/ser1sign.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="shadow-center-top-item">
                            <div class="shadow-center-top-item-top">
                                <img class="img" src="@/img/ser1-4.jpg" alt="">
                            </div>
                            <div class="shadow-center-top-item-bottom">
                                <div class="shadow-center-top-item-bottom-left">
                                    <div class="shadow-center-top-item-bottom-left-top">规划</div>
                                    <div class="shadow-center-top-item-bottom-left-bottom">产品设计</div>
                                </div>
                                <div class="shadow-center-top-item-bottom-right">
                                    <img class="img" src="@/img/ser1sign.png" alt="">
                                </div>
                            </div>
                        </div>
<!--                        <div class="shadow-center-top-item">-->
<!--                            <div class="shadow-center-top-item-top">-->
<!--                                <img class="img" src="@/img/ser1-5.jpg" alt="">-->
<!--                            </div>-->
<!--                            <div class="shadow-center-top-item-bottom">-->
<!--                                <div class="shadow-center-top-item-bottom-left">-->
<!--                                    <div class="shadow-center-top-item-bottom-left-top">策划</div>-->
<!--                                    <div class="shadow-center-top-item-bottom-left-bottom">运营体系</div>-->
<!--                                </div>-->
<!--                                <div class="shadow-center-top-item-bottom-right">-->
<!--                                    <img class="img" src="@/img/ser1sign.png" alt="">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <div class="shadow-center-bottom"></div>
                </div>
                <div class="shadow-bottom">
                    <div class="shadow-bottom-top">
                        <div>
                            <span class="wy" style="font-size: var(--s-t-size);">
                                为客户量身定制 打造品牌专属
                            </span>
                        </div>
                        <div class="shadow-bottom-top-bottom">
                         开放互通的项目实施流程，从策划到执行，从UI到交互、从技术到内容等，完美契合品牌战略需求，耗时少，落地快，节省沟通成本，降低实施风险。
                        </div>
                    </div>
                    <!--                    <div style="display: flex;justify-content: center;align-items: center">-->
                    <div class="shadow-bottom-bottom">
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">需求分析</div>
                            </div>
                        </div>
                        <div class="shadow-bottom-bottom-item-right">
                            <img class="img" src="@/img/ser1icon1.png" alt="">
                        </div>
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">策划构思</div>
                            </div>
                        </div>
                        <div class="shadow-bottom-bottom-item-right">
                            <img class="img" src="@/img/ser1icon1.png" alt="">
                        </div>
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">框架搭建</div>
                            </div>

                        </div>
                        <div class="shadow-bottom-bottom-item-right">
                            <img class="img" src="@/img/ser1icon1.png" alt="">
                        </div>
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">视觉交互</div>
                            </div>

                        </div>
                        <div class="shadow-bottom-bottom-item-right">
                            <img class="img" src="@/img/ser1icon1.png" alt="">
                        </div>
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">开发执行</div>
                            </div>

                        </div>
                        <div class="shadow-bottom-bottom-item-right">
                            <img class="img" src="@/img/ser1icon1.png" alt="">
                        </div>
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">上线测试</div>
                            </div>

                        </div>
                        <div class="shadow-bottom-bottom-item-right">
                            <img class="img" src="@/img/ser1icon1.png" alt="">
                        </div>
                        <div class="shadow-bottom-bottom-item">
                            <div class="shadow-bottom-bottom-item-left">
                                <div class="shadow-bottom-bottom-item-left-left">
                                    <img class="img" src="@/img/ser1icon.png" alt="">
                                </div>
                                <div class="shadow-bottom-bottom-item-left-right">基于运营的综合服务</div>
                            </div>
                        </div>
                    </div>
                    <!--                    </div>-->
                </div>
            </div>
        </div>
        <div :class="swiper1[2].swiperclass==0?'noswiper':(swiper1[2].swiperclass==1?'reswiper':'swiper')" :style="'z-index:'+swiper1[2].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[2].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow">
                <div class="shadow-top"  style="top: 22%">
                    <div class="shadow-top-top">
                        <div class="shadow-top-top-item"><span class="wy" style="font-size: 25px;">解构创意</span></div>
                        <div class="shadow-top-top-item"><span class="wy"  style="font-size: 25px;">让技术真正插上思维的翅膀</span></div>
                    </div>
                    <div class="shadow-top-bottom">
                      从创意到实现，帮助企业准确的设计和实施，并保证其一致性及有效性，专业、规范，让每次服务都具备产品思维。
                    </div>
                </div>
                <div class="shadow-center" style="top:43%">
                    <div class="shadow-center-top">
<!--                        <div class="shadow-center-top-item1">-->
<!--                            <div class="shadow-center-top-item1-top" style="width: 28px;height: 37px">-->
<!--                                <img src="@/img/ser2icon1.png" alt="">-->
<!--                            </div>-->
<!--                            <span class="shadow-center-top-item1-center">个性定制</span>-->
<!--                            <div class="shadow-center-top-item1-bottom" :style="componentData==1024?'width:142.5px':''">基于战略的策划构思，加上专业的技术和服务能力，保证每个客户得到匹配的产品，绝非生搬硬套式的开发</div>-->
<!--                        </div>-->
                        <div class="shadow-center-top-item1" :style="componentData?'margin-left:10px':''">
                            <div class="shadow-center-top-item1-top" >
                                <img :src="base_url+'/public/images/ser2icon2.png'"  alt="">
                            </div>
                            <span class="shadow-center-top-item1-center">开发规范</span>
                            <div class="shadow-center-top-item1-bottom" :style="componentData?'width:142.5px':''">采用专业工具，从创意规范，设计规范，制作规范等方面，保证项目的每个环节的规范性</div>
                        </div>
                        <div class="shadow-center-top-item1" :style="componentData?'margin-left:10px':''">
                            <div class="shadow-center-top-item1-top" >
                                <img :src="base_url+'/public/images/ser2icon3.png'" alt="">
                            </div>
                            <div class="shadow-center-top-item1-center">高效响应</div>
                            <div class="shadow-center-top-item1-bottom" :style="componentData?'width:142.5px':''">不管是项目前期，或是开发中、后期维护等阶段，顾问及技术团队，7*24小时快速响应，保证服务效率</div>
                        </div>
                        <div class="shadow-center-top-item1" :style="componentData?'margin-left:10px':''">
                            <div class="shadow-center-top-item1-top" >
                                <img :src="base_url+'/public/images/ser2icon4.png'" alt="">
                            </div>
                            <div class="shadow-center-top-item1-center">安全稳定</div>
                            <div class="shadow-center-top-item1-bottom" :style="componentData?'width:142.5px':''">为客户配备目前稳定的数字技术服务，并定时关注客户产品的运作状态，及时作出反馈</div>
                        </div>
                        <div class="shadow-center-top-item1" :style="componentData?'margin-left:10px':''">
                            <div class="shadow-center-top-item1-top">
                                <img :src="base_url+'/public/images/ser2icon5.png'" alt="">
                            </div>
                            <div class="shadow-center-top-item1-center">完整交付</div>
                            <div class="shadow-center-top-item1-bottom" :style="componentData?'width:142.5px':''">域名丨服务器丨备案丨媒体等账号、源代码、技术文档、创意构思、设计效果等所有输出物交付给客户</div>
                        </div>
<!--                        <div class="shadow-center-top-item1" :style="componentData==1024?'margin-left:10px':''">-->
<!--                            <div class="shadow-center-top-item1-top" style="width: 39px;height: 37px">-->
<!--                                <img src="@/img/ser2icon6.png" alt="">-->
<!--                            </div>-->
<!--                            <div class="shadow-center-top-item1-center">综合服务</div>-->
<!--                            <div class="shadow-center-top-item1-bottom" :style="componentData==1024?'width:142.5px':''">后期维护、运营策划、内容输出、营销策划等后期综合服务，为每一个客户的战略及运营，保驾护航</div>-->
<!--                        </div>-->
                    </div>
                </div>
                <div class="shadow-bottom" :style="componentData?'top:72%;right:5%;':'top:68.9%'">
<!--                    <div class="shadow-bottom-top">-->
<!--                        <div class="shadow-bottom-top-top">-->
<!--                            <span class="wy" style="font-size: var(&#45;&#45;s-t-size);">这也许是您即将选择我们的理由</span>-->
<!--                        </div>-->
<!--                        <div class="shadow-bottom-top-bottom">-->
<!--                            我们在每个项目的服务中，配备专业且完善的服务团队，包括产品经理、创意总监、运营总监、技术总监、设计总监等高端人才。-->
<!--                        </div>-->
<!--                    </div>-->
                    <!--                    <div style="display: flex;justify-content: center;align-items: center">-->
                    <div class="shadow-bottom-bottom" :style="componentData?'flex-wrap: nowrap;margin-top:10px':'flex-wrap: nowrap'">
<!--                        <div class="shadow-bottom-bottom-item1" :style="componentData==1024?'min-width:300px;height:96px':''" @click.stop>-->
<!--                            <img src="@/img/ser2bottom1.png" class="bac" alt="">-->
<!--                            <div class="shadow-bottom-bottom-item1-shadow">-->
<!--                                <div class="shadow-bottom-bottom-item1-shadow-top">-->
<!--                                    <div class="shadow-bottom-bottom-item1-shadow-top-left">-->
<!--                                        <div class="shadow-bottom-bottom-item1-shadow-top-left-top">-->
<!--                                            策略服务-->
<!--                                        </div>-->
<!--                                        <div class="shadow-bottom-bottom-item1-shadow-top-left-bottom">根据品牌战略而制定的数字化策略、运营策略</div>-->
<!--                                    </div>-->
<!--                                    <div class="shadow-bottom-bottom-item1-shadow-top-right">-->
<!--                                        <img src="@/img/ser2bottomicon.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="shadow-bottom-bottom-item1-shadow-bottom">获取您需要的服务</div>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="shadow-bottom-bottom-item1"  @click="handlerouter({'path':'/xh','query':{'target':3}})" :style="componentData?'min-width:350px;height:110px':''" @click.stop>
                            <img src="@/img/ser2bottom2.png" class="bac"  alt="">
                            <div class="shadow-bottom-bottom-item1-shadow">
                                <div class="shadow-bottom-bottom-item1-shadow-top">
                                    <div class="shadow-bottom-bottom-item1-shadow-top-left">
                                        <div class="shadow-bottom-bottom-item1-shadow-top-left-top">
                                            视觉服务
                                        </div>
                                        <div class="shadow-bottom-bottom-item1-shadow-top-left-bottom">UI设计、VI设计、IP设计、内容规划等相关视觉服务</div>
                                    </div>
                                    <div class="shadow-bottom-bottom-item1-shadow-top-right">
                                        <img src="@/img/ser2bottomicon.png" alt="">
                                    </div>
                                </div>
                                <div class="shadow-bottom-bottom-item1-shadow-bottom">获取您需要的服务</div>
                            </div>
                        </div>
                        <div class="shadow-bottom-bottom-item1" @click.stop="handlerouter({'path':'/xh','query':{'target':3}})" :style="componentData?'min-width:350px;height:110px':''">
                            <img src="@/img/ser2bottom3.png" class="bac"  alt="">
                            <div class="shadow-bottom-bottom-item1-shadow">
                                <div class="shadow-bottom-bottom-item1-shadow-top">
                                    <div class="shadow-bottom-bottom-item1-shadow-top-left">
                                        <div class="shadow-bottom-bottom-item1-shadow-top-left-top">
                                            技术服务
                                        </div>
                                        <div class="shadow-bottom-bottom-item1-shadow-top-left-bottom">基于品牌策略下的技术规划、开发服务</div>
                                    </div>
                                    <div class="shadow-bottom-bottom-item1-shadow-top-right">
                                        <img src="@/img/ser2bottomicon.png" alt="">
                                    </div>
                                </div>
                                <div class="shadow-bottom-bottom-item1-shadow-bottom">获取您需要的服务</div>
                            </div>
                        </div>
                    </div>
                    <!--                    </div>-->
                </div>
            </div>
        </div>

        <div :class="swiper1[3].swiperclass==0?'noswiper':'reswiper'" :style="'z-index:'+swiper1[3].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[3].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow">
                <div class="shadow-top" style="top: 22%">
                    <div class="shadow-top-top">
                        <div class="shadow-top-top-item"><span class="wy" style="font-size: 25px;">用心服务</span></div>
                        <div class="shadow-top-top-item"><span class="wy" style="font-size: 25px;">每一个项目都全力以赴 不负重托</span></div>
                    </div>
                    <div class="shadow-top-bottom">
                     我们致力于打造有温度、有深度、有价值的综合服务，坚持以客户为中心的驱动力，从想象到实现，每一步都有我们陪伴。
                    </div>
                </div>
                <div class="shadow-center" style="top:51.3%">
                    <div class="shadow-center-top" style="justify-content: center">
                        <div class="shadow-center-top-item3"></div>
                        <div class="shadow-center-top-item3"></div>
                        <div class="shadow-center-top-item3"></div>
                    </div>
                </div>
                <div class="shadow-bottom" style="top:76%;">
                    <div class="shadow-bottom-top" style="display: flex;flex-direction: column;align-items: center">
                        <div class="wy" style="font-size: var(--s-t-size);">
                            这也许是您即将选择我们的理由
                        </div>
                        <div class="shadow-bottom-top-bottom" style="text-align: center">
                            我们在每个项目的服务中，配备专业且完善的服务团队，包括产品经理、创意总监、运营总监、技术总监、设计总监等高端人才。
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import s1 from '../img/s1.jpg'
    import s2 from '../img/s2.jpg'
    import s3 from '../img/s3.jpg'
    import s4 from '../img/s4.jpg'
    import {base_url} from "../api/base_url";
    //白色进度条
    import diybar from "../components/diybar";

    export default {
        data(){
            return{
              base_url:base_url(),
                //轮播到哪一页，0为第一页，1为第二页，2为第三页，3为第四页，-1为跳转上一页路由，4为跳转下一页路由
                swiperchoose: 0,
                //记录变化前的swiperchoose
                oddswiperchoose:0,


                //界面宽度的一半
                resizeX:0,


                //节流，阻止事件
                preventcontact:0,


                //轮播
                swiper1: [
                    {
                        //背景图
                        bac: s1,
                        //高度
                        indexz:99,
                        //判断左移还是右移的class,1为左移，-1为右移，0为进入页面不动的默认情况
                        swiperclass:0,
                        diybarposition:0,
                    },
                    {
                        bac: s2,
                        indexz:-1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    },
                    {
                        bac: s3,
                        indexz:-1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    },
                    {
                        bac: s4,
                        indexz:1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    }
                ],
                //diybar配置
                diybarData:{
                    //节点数量
                    count:4,
                    //目标节点位置
                    targetleft:35,
                    beforedetroy : false,
                    target:0,
                },
            }
        },
        components:{
            diybar,
        },
        props:{
            componentData:{
                default:0,
                type:Number,
            }
        },
        created() {
            //监听鼠标滚动
            setTimeout(()=>{
                window.addEventListener('mousewheel',this.handlemouse)
            },500)
            //监听网页宽度
            window.addEventListener('resize',this.resize)
            this.resize()

            //监听按键
            setTimeout(()=>{
                    window.addEventListener('keydown',this.keydown)
            },500)
            //用于选择内页
            let i = this.$route.query.target
            if(i){
              this.diybarData.target = i
              this.swiperchoose = i
            }
        },
        beforeUnmount() {
            this.diybarData.beforedetroy = false
        },
        methods: {
            //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
            handleswiperchoose(e) {
              //监听是否全屏
              let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
              if(!isFullScreen){
                return;
              }
                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                let X = e.clientX
                // if(this.componentData){
                //     X = e.clientY
                // }
                if(X>=this.resizeX){
                    this.swiperchoose++
                }else{
                    this.swiperchoose--
                }


                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            handlediybar(e){
                if(e.target==this.swiperchoose){
                    return
                }
                this.swiperchoose=e.target
                this.diybarData.target =e.target
            },
            //监听鼠标滚动事件,上滚回退上一张，下滚继续下一张
            handlemouse(e){

                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.deltaY>0){
                    this.swiperchoose++

                }else{

                    this.swiperchoose--


                }

                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            resize(){
                if(this.componentData){
                    this.resizeX = this.componentData/2
                    return
                }
                this.resizeX = document.body.clientWidth/2
            },
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },
            //监听键盘
            keydown(e){
                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.keyCode==39){
                    this.swiperchoose++
                }else if(e.keyCode==37){
                    this.swiperchoose--
                }else if(e.keyCode==38){
                    this.swiperchoose=-1
                }else if(e.keyCode==40){
                    this.swiperchoose=4
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            }
        },

        watch:{
            swiperchoose:function (e) {
                if(e>3){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index+1].router)
                    return
                }else if(e<0){
                  this.handlerouter({'path':this.$store.state.routerlist[this.$route.meta.index-1].router,'query':{'target':3}})
                  return;
                }
                if(e==0){
                    this.swiper1[0].indexz=99
                    this.swiper1[2].indexz=-1
                    this.swiper1[3].indexz=-1
                    this.swiper1[1].indexz=-1
                    if(this.oddswiperchoose>=1){
                        this.swiper1[0].swiperclass= -1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                    this.swiper1[3].swiperclass= 0



                }else if(e==1){
                    this.swiper1[1].indexz=99
                    this.swiper1[3].indexz=-1
                    this.swiper1[0].indexz=-1
                    this.swiper1[2].indexz=-1
                    if(this.oddswiperchoose>=2){
                        this.swiper1[1].swiperclass= -1
                    }else{
                        this.swiper1[1].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                    this.swiper1[3].swiperclass= 0
                }else if(e==2){
                    this.swiper1[0].indexz=-1
                    this.swiper1[2].indexz=99
                    this.swiper1[1].indexz=-1
                    this.swiper1[3].indexz=-1
                    if(this.oddswiperchoose>=3){
                        this.swiper1[2].swiperclass= -1

                    }else{
                        this.swiper1[2].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[3].swiperclass= 0
                }else if(e==3){
                    this.swiper1[0].indexz=-1
                    this.swiper1[1].indexz=-1
                    this.swiper1[3].indexz=99
                    this.swiper1[2].indexz=-1
                    if(this.oddswiperchoose<=2){
                        this.swiper1[3].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                }
                this.oddswiperchoose = e
                this.diybarData.target= e
                this.diybarData.position = this.swiper1[e].diybarposition
            }
        },
    }
</script>

<style lang="scss" scoped>
    .swiper{
        position: absolute;
        left: -100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnright;
    }
    @keyframes turnright {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    .reswiper{
        position: absolute;
        left: 100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnleft;
    }
    @keyframes turnleft {
        0% {
            left: 100%;
        }
        100% {
            left: 0;
        }
    }

    .noswiper{
        position: absolute;
        left:0%;
        width: 100%;
        height: 100%;
    }

    .swiper-unit-home{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: var(--b-t-size);
        width: 675px;
        transform: translateY(-50%);
        margin-left: 4%;
    }
    .swiper-home{
        position: absolute;
        left: 2%;
        right: 0;
        top: 50%;
    }
    .bottom-home{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 16%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
    .bottom-right-home{
        display: flex;
        flex-direction: row;
        width: 428px;
        font-size: var(--s-c-size);
        color: rgba(255,255,255,0.9);
        margin-right: 8%;
    }
    .bottom-right-left-home{
        background: rgba(255,255,255,0.9);
        width: 1.5px;
        height: 40px;
        margin-right: 34px;
        margin-top: 7px;
    }
    .swiper1-home{
        line-height: 2;
        position: relative;
        width: 100%;
    }
    .swiper1-unit-home{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      text-indent: 28px;
    }
    .shadow-top{
        position: absolute;
        top:22% ;
        left: 5%;
        @media screen and (max-width: 1650px) {
            top:15%
        }
    }
    .shadow-top{
        display: flex;
        flex-direction: column;
    }

    .shadow-top-bottom{
        font-size: var(--s-c-size);
        width: 500px;
        margin-top: 23px;
        line-height:1.9;


        text-indent: 28px;
    }
    .shadow-center{
        position: absolute;
        top:43%;
        left: 5%;
        right: 8.7%;
        @media screen and (max-width: 1650px) {
            top:36%
        }
    }
    .shadow-center-top{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .shadow-center-top-item{
        display: flex;
        flex-direction: column;
        font-size: 13px;
    }
    .shadow-center-top-item:first-of-type{
        margin-left: 0;
    }
    .shadow-center-top-item-top{
        //width: 205px;
        //height: 126px;
      width: 325.4px;
      height: 200px;
        opacity: 0.6;
        margin-bottom: 20px;
        @media screen and (max-width: 1650px) {
            //height: 100px;
            //width: 163px;
          @media screen and (max-width: 1400px) {
            height: 120px;
            width: 195px;
          }
        }
    }

    .shadow-center-top-item-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .shadow-center-top-item-bottom-left{
        margin-left: 13px;
    }
    .shadow-center-top-item-bottom-right{
        width: 27px;
        height: 29px;
    }
    .shadow-center-bottom{
        width: 100%;
        height: 12px;
        background: linear-gradient(to right,#092d46,#061622);
        margin-top: 29px;
      @media screen and (max-width: 1400px) {
        margin-top:15px;

      }
    }
    .shadow-bottom{
        position: absolute;
        left: 5%;
        top:76%;
        right:8.7%;
        @media screen and (max-width: 1650px) {
            top: 69%;
          @media screen and (max-width: 1400px) {
            top: 65%;
          }
        }
    }

    .shadow-bottom-top-bottom{
        font-size: var(--s-c-size);
        width: 500px;
        line-height: 1.9;
        margin-top: 8px;


        text-indent: 28px;
    }
    .shadow-bottom-bottom{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 39px;
        flex-wrap: wrap;
        //justify-content: space-between;
        //@media screen and (max-width: 1400px) {
            justify-content: flex-start;
        //}
    }
    .shadow-bottom-bottom-item{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .shadow-bottom-bottom-item-left{
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: linear-gradient(to right,#015794,#082439);
        padding-top: 12px;
        padding-bottom:18px ;
        padding-left: 14px;
        padding-right: 28px;
    }
    .shadow-bottom-bottom-item-left-left{
        width: 23px;
        height: 23px;
    }
    .shadow-bottom-bottom-item-left-right{
        margin-left: 16px;
        font-size: var(--s-c-size);
    }
    .shadow-bottom-bottom-item-right{
        width: 26px;
        height: 25px;
        margin-top: 10px ;
        margin-left: 19px;
        margin-right: 19px;
    }
    .shadow-center-top-item1{
        height: 195px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .shadow-center-top-item1-bottom{
        font-size: var(--s-c-size);
        width: 198.5px;
        line-height: 1.5;
      text-indent: 28px;
    }
    .shadow-center-top-item1-center{
        font-size: var(--s-t-size);
        background: linear-gradient(to right,#0173c6 30%,#034677 );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        flex: none;
        position: absolute;
        top: 59px;
    }
    .shadow-bottom-bottom-item1{
        width: 583px;
        height: 120px;
        min-width: 360px;
        position: relative;
      //margin-left: 10px;
      margin-left: 30px;
        cursor: pointer;
    }
    .shadow-bottom-bottom-item1:first-of-type{
        margin-left: 0;
    }
    .shadow-bottom-bottom-item1-shadow {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        padding-top: 18px;
        padding-bottom: 9px;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 17px;
        background-image: linear-gradient(to right,rgba(#085ca4,0.9),rgba(#11365d,0.5) 80%);
    }
    .shadow-bottom-bottom-item1-shadow-top{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .shadow-bottom-bottom-item1-shadow-top-left-top{
        font-size: 20px;
    }
    .shadow-bottom-bottom-item1-shadow-top-left-bottom{
        font-size: 15px;
      margin-top: 5px;
    }
    .shadow-bottom-bottom-item1-shadow-top-right{
        width: 50px;
        height: 50px;
    }
    .shadow-bottom-bottom-item1-shadow-bottom{
        font-size: 13px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .shadow-center-top-item3{
        width: 297px;
        height: 104px;
    }
    .shadow-center-top-item3:nth-child(1){
        background-image: linear-gradient(to right,rgba(#031927,0.8),rgba(#034474,0.9));
    }
    .shadow-center-top-item3:nth-child(2){
        background-image: linear-gradient(to right,rgba(#665627,0.7),rgba(#3c3013,0.7));
    }
    .shadow-center-top-item3:nth-child(3){
        background-image: linear-gradient(to right,rgba(#0078ce,0.9),rgba(#050f18,0.5));
    }
    .swiper-unit-right-home{
        display: flex;
        flex-direction: column;
    }
    .shadow-center-top-item1-top{
      width: 50px;
      height: 50px;
      margin-left: -10px;
    }
</style>
